.custom-checkbox-2 [data-reach-custom-checkbox-container] {
    border-radius: 8px;
	background-color: #FFF;
    color: #ADB2BC;
	cursor: pointer;
    margin-right: 0;
    width: unset;
    height: 22px;
    align-items: center;
    display: flex;
    -webkit-tap-highlight-color: transparent;
}
.custom-checkbox-2 [data-reach-custom-checkbox-container][data-state="checked"] {
    background-color: #665E9B;
    color: #FFF;
	transition: background-color 20ms linear, border-color 200ms linear;
}
.custom-checkbox-2 [data-reach-custom-checkbox-container][data-state="unchecked"] {
    transition: background-color 200ms linear, border-color 200ms linear;
    color: #ADB2BC;
	background-color: #FFF;
}
.custom-checkbox-2 [data-reach-custom-checkbox-container][data-state="mixed"] {
    background-color: #665E9B;
    color: #FFF;
    transition: background-color 200ms linear, border-color 200ms linear;
}
.custom-checkbox-2 [data-reach-custom-checkbox-container]:focus-within, .custom-checkbox-2 [data-reach-custom-checkbox-container][data-focus] {
    box-shadow: none;
    outline: none;
}
.custom-checkbox-2 [data-reach-custom-checkbox-container][data-state="checked"]:hover {
    filter: brightness(90%);
}
.custom-checkbox-2 [data-reach-custom-checkbox-container][data-state="unchecked"]:hover {
    filter: brightness(95%);
}

.custom-checkbox-2[disabled] [data-reach-custom-checkbox-container]{
    cursor: not-allowed;
    background: darkgray;
	opacity: 0.6;
}
.custom-checkbox-2[disabled] {
	opacity: 0.6;
}