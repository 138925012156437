.custom-toast {
    font-family: 'Inter', sans-serif;
    font-weight: 600 !important;
    color: #4A4A4A;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: -0.35px;
    border-radius: 9px;
    text-align: center;
}

@keyframes keyframes-enter {
    0% {
        opacity: 0;
        transform: translateY(-500px)
    }
    100% {
        opacity: 1;
        transform: translateY(0px)
    }
}

.toaster-enter {
    animation: keyframes-enter 0.2s ease-out both;
}

@keyframes keyframes-exit {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.toaster-exit {
    animation: keyframes-exit 0.2s ease-in both;
}